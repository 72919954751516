.main {
  margin-left: 64px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background-color: beige;

  .element {
    margin: 2%;
  }
}