@import './fonts';
@import './colors';
@import './mediaQueriesMixings.scss';

* {
  margin: 0;
}
html, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Geometria';
  font-size: 14px;
  color: $grey-main;
  height: 100%;
}

h1, h2, h3, h4 {
  font-family: 'Geometria-Bold';
  color: $grey-dark;
  margin: 0;
}

h1 {
  font-size: 34px;
  line-height: 43px;

  @include sm {
    font-size: 28px;
    line-height: 35px;
  }
}

h2 {
  font-size: 24px;
  line-height: 24px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
}

button {
  // border-radius: 5px;
  // height: 54px;
  cursor: pointer;
  &:focus {outline:0;}
}

a {
  text-decoration: none;
  margin: 0;
  display: block;
}

.bold {
  font-family: 'Geometria-Bold';
}

// input[type="text"], input[type="number"], input[type="email"], input[type="select"], input[type="tel"], select {
//   height: 46px;
//   padding-left: 15px;
//   border: 1px solid $grey-extra-light;
//   box-sizing: border-box;
//   border-radius: 5px;
//   font-family: 'Geometria';
// }

// * {
//  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//  -webkit-tap-highlight-color: transparent;
//  -webkit-user-select: text;
//  -khtml-user-select: text;
//  -moz-user-select: text;
//  -ms-user-select: text;
//   user-select: text;
// }

// textarea {
//   padding: 15px;
//   border-radius: 5px;
//   font-family: 'Geometria';
//   border: 1px solid $grey-extra-light;
//   box-sizing: border-box;
// }

// select {
//   appearance: none;
//   outline: none;
//   background: url("data:image/svg+xml;utf8,<svg fill='%23BDBDBD' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") 100% 50% no-repeat transparent;
// }

